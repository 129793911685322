<template>
  <el-dropdown size="mini" split-button type="primary" @command="switchLocale">
    <span>{{ currentLocale }}</span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item class="flags" command="fr">
        <span :class="getFlagClass('fr')"> FR </span>
      </el-dropdown-item>
      <el-dropdown-item class="flags" command="en">
        <span :class="getFlagClass('en')"> EN </span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "MzSwitchLang",
  props: ["disabled", "to", "type"],

  computed: {
    ...mapGetters("api", ["isLogged"]),

    currentLocale() {
      return this.$i18n.locale.toUpperCase();
    },
    getFlagClass() {
      return (locale) => {
        return { isCurrent: this.$i18n.locale === locale };
      };
    },
  },
  mounted() {
    if (
      this.$route.query.lang &&
      ["en", "fr"].indexOf(this.$route.query.lang) >= 0
    ) {
      const lang = this.$route.query.lang;

      this.$route.query.lang = undefined;
      this.switchLocale(lang);

      // remove lang params from window location
      let url = document.location.href.split("?")[0];
      window.history.pushState("", document.title, url); // added this line to push the new url directly to url bar .;
    }
  },
  methods: {
    ...mapActions({
      doChangeUserData: "api/API_CHANGE_USERDATA",
    }),

    switchLocale(lang) {
      this.$i18n.setLocale(lang);
      if (this.isLogged) {
        this.doChangeUserData({ lang });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.flags {
  margin: 0.5rem 1.5rem;
}
.flags {
  :not(.isCurrent) {
    color: #000;
  }
}
</style>