<template>
  <div>
    <el-dialog
      :title="'Create new Group Offer'"
      :visible.sync="displayModal"
      :close-on-click-modal="false"
      width="50%"
      append-to-body
    >
      <div v-loading="loading">
        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Name</h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-input v-model="form.name" />
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Lang</h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-select v-model="form.lang">
              <el-option value="fr" label="FR"></el-option>
              <el-option value="en" label="EN"></el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Market type</h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-select v-model="form.marketType">
              <el-option
                v-for="item in listOrganizationMarketType"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Collaboration type</h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-select v-model="form.collaborationType">
              <el-option :value="'email'" :label="'By email'"></el-option>
              <el-option :value="'nickname'" :label="'By nickname'"></el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Tags</h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-select
              class="el-col-24"
              v-model="form.tags"
              multiple
              filterable
              allow-create
              default-first-option
              :placeholder="$t('public.edit_magazine.add_tags')"
            >
              <el-option
                v-for="(item, idx) in organizationTagIndex"
                :key="idx + item"
                :value="item"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Contact email</h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-input v-model="form.contactEmail" />
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Comment</h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-input type="textarea" v-model="form.adminComment" />
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Auth Strategy</h2>
          </el-col>
          <el-col :span="6" class="ml-2">
            <el-select v-model="form.authentificationStrategy">
              <el-option label="Login / Password" value="login_password" />
              <el-option label="Login / Code" value="login_code" />
              <el-option label="SSO" value="sso" />
            </el-select>
          </el-col>
          <el-col :span="8" v-if="form.authentificationStrategy === 'sso'">
            <el-select
              v-model="form.ssoConfiguration"
              :placeholder="'Select default configuration'"
              class="el-col el-col-24"
            >
              <el-option
                v-for="config in authStrategies"
                :key="config._id"
                :label="config.key"
                :value="config._id"
              />
            </el-select>
            <el-select
              v-model="form.alternativeSsoConfiguration"
              :placeholder="'Select alternative configuration'"
              class="mt-1 el-col el-col-24"
              :disabled="!form.ssoConfiguration"
              :clearable="true"
            >
              <el-option
                v-for="config in authStrategies"
                :key="config._id"
                :label="config.key"
                :value="config._id"
              />
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Scope</h2>
          </el-col>
          <el-col :span="14">
            <el-radio-group
              v-model="displayTextareaList"
              size="mini"
              style="float: right"
            >
              <el-radio-button :label="false"> Input list </el-radio-button>
              <el-radio-button :label="true"> Textarea </el-radio-button>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row class="mt-1">
          <el-col :span="14" :offset="8">
            <el-input
              class="ml-2"
              v-if="displayTextareaList"
              type="textarea"
              :rows="5"
              placeholder="Please insert list of Emails or RNE separate with ';'"
              v-model="scopeList"
            ></el-input>

            <el-row
              class="ml-2"
              type="flex"
              v-else
              v-for="(email, index) in form.scope"
              :key="index"
            >
              <el-input v-model="email.value">
                <el-button
                  slot="append"
                  icon="el-icon-la-trash"
                  @click.prevent="removeScope(index)"
                ></el-button>
              </el-input>
              <el-button
                icon="el-icon-la-plus"
                type="success"
                circle
                class="add-scope-button"
                @click.prevent="addScope()"
                v-if="index === form.scope.length - 1"
              ></el-button>
            </el-row>
          </el-col>
        </el-row>

        <AdminAddOfferForm ref="formOffer" :showAvailableTemplate="true" />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click="onClickCancel"
          v-t="'public.alert.cancel'"
        ></el-button>
        <el-button
          type="primary"
          v-t="'public.alert.confirm'"
          :disabled="validateButtonDisabled"
          @click="onClickValidate()"
        ></el-button>
        <el-button
          type="primary"
          :disabled="validateButtonDisabled"
          @click="onClickValidate(true)"
          >Valider et Continuer</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import AdminAddOfferForm from "~/components/offer/AdminAddOfferForm";

export default {
  name: "NewGroupOfferModal",

  components: { AdminAddOfferForm },

  props: [],

  computed: {
    ...mapState("organization", ["organization", "organizationTagIndex"]),
    ...mapGetters("organization", ["listOrganizationMarketType"]),
    ...mapState("authStrategy", ["authStrategies"]),

    validateButtonDisabled() {
      return (
        this.loading === true ||
        this.form.name === null ||
        this.form.name.trim().length < 2
      );
    },

    scopeList: {
      get() {
        const scope = this.form.scope.reduce((list, email) => {
          list.push(email.value);
          return list;
        }, []);
        return scope.join(";");
      },
      set(value) {
        const scope = value.split(";");
        if (scope.length === 0) {
          // add empty first scope
          this.form.scope.push({ value: "" });
        } else {
          this.form.scope = scope.reduce((list, email) => {
            list.push({
              value: email.trim(),
            });
            return list;
          }, []);
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      displayModal: false,
      displayTextareaList: false,
      form: {
        name: null,
        lang: "fr",
        marketType: "EDU",
        collaborationType: "nickname",
        tags: [],
        contactEmail: "",
        adminComment: "",
        authentificationStrategy: "login_password",
        ssoConfiguration: null,
        alternativeSsoConfiguration: null,
        scope: [{ value: "" }],
      },
    };
  },

  mounted() {
    this.loadAuthStrategies();
  },
  methods: {
    ...mapActions({
      saveGroupOffer: "organization/SAVE_GROUPOFFER",
      loadAuthStrategies: "authStrategy/LOAD_AUTHSTRATEGIES",
    }),
    ...mapMutations({}),

    hide() {
      // reset popup forms
      this.displayTextareaList = false;
      this.form.name = null;
      this.form.lang = "fr";
      this.form.marketType = "EDU";
      this.form.collaborationType = "nickname";
      this.form.tags = [];
      this.form.contactEmail = "";
      this.form.adminComment = "";
      this.form.authentificationStrategy = "login_password";
      this.form.ssoConfiguration = null;
      this.form.alternativeSsoConfiguration = null;
      this.form.scope = [{ value: "" }];
      this.$refs.formOffer.resetForm();

      this.displayModal = false;
    },
    show() {
      this.displayModal = true;
    },

    onClickCancel() {
      this.hide();
    },
    onClickValidate(reopenCreate = false) {
      if (!this.$refs.formOffer.checkForm()) {
        return;
      }

      this.loading = true;

      if (!this.form.authentificationStrategy === "sso") {
        this.form.ssoConfiguration = null;
        this.form.alternativeSsoConfiguration = null;
      }

      if (
        this.form.alternativeSsoConfiguration &&
        this.form.alternativeSsoConfiguration.length === 0
      ) {
        this.form.alternativeSsoConfiguration = null;
      }

      let params = {
        ...this.form,
        offer: this.$refs.formOffer.getForm(),
      };

      this.saveGroupOffer(params).then((success) => {
        if (success) {
          this.loading = false;
          // go to edit organization if success
          this.hide();
          if (reopenCreate) {
            this.$nextTick(() => {
              this.show();
            });
          } else {
            this.$go2Route({
              name: "admin-organization-edit-organizationId",
              params: { organizationId: this.organization._id },
            });
          }
        } else {
          this.loading = false;
        }
      });
    },
    addScope() {
      this.form.scope.push({ value: "" });
    },
    removeScope(index) {
      if (this.form.scope.length > 1) {
        this.form.scope.splice(index, 1);
      } else {
        this.form.scope[0].value = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.add-scope-button {
  right: -75px;
  position: absolute;
}
</style>