<template>
  <div>
    <el-dialog
      :title="$t('admin.organizations.createNewModal.title')"
      :visible.sync="displayModal"
      :close-on-click-modal="false"
      width="50%"
      append-to-body
    >
      <div v-loading="loading">
        <el-row class="mt-2">
          <el-col :span="8">
            <h2 v-t="'admin.organizations.createNewModal.name'"></h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-input v-model="form.name" />
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Status</h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-select v-model="form.status" placeholder="Select a status">
              <el-option label="Active" value="active"> </el-option>
              <el-option label="Suspended" value="suspended"> </el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Market type</h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-select v-model="form.marketType">
              <el-option
                v-for="item in listOrganizationMarketType"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Collaboration type</h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-select v-model="form.collaborationType">
              <el-option :value="'email'" :label="'By email'"></el-option>
              <el-option :value="'nickname'" :label="'By nickname'"></el-option>
            </el-select>
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :span="8">
            <h2>Contact email <small>(optionnal)</small></h2>
          </el-col>
          <el-col :span="14" class="ml-2">
            <el-input v-model="form.contactEmail" />
          </el-col>
        </el-row>

        <AdminAddOfferForm
          ref="formOffer"
          :isOptionnal="true"
          :showAvailableTemplate="true"
        />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click="onClickCancel"
          v-t="'public.alert.cancel'"
        ></el-button>
        <el-button
          type="primary"
          v-t="'public.alert.confirm'"
          :disabled="validateButtonDisabled"
          @click="onClickValidate"
        ></el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import Validators from "~/utils/validators";
import AdminAddOfferForm from "~/components/offer/AdminAddOfferForm";

export default {
  name: "NewOrganizationModal",

  components: {
    AdminAddOfferForm,
  },

  props: [],

  computed: {
    ...mapState("organization", ["organization"]),
    ...mapGetters("organization", ["listOrganizationMarketType"]),

    validateButtonDisabled() {
      return (
        this.loading === true ||
        this.form.name === null ||
        this.form.name.trim().length < 2
      );
    },
  },
  data() {
    return {
      loading: false,
      displayModal: false,
      form: {
        name: null,
        contactEmail: null,
        collaborationType: "nickname",
        status: "active",
        marketType: "B2B",
      },
    };
  },

  mounted() {
    this.resetOrganization();
  },
  methods: {
    ...mapActions({
      saveOrganization: "organization/SAVE_ORGANIZATION",
    }),
    ...mapMutations({
      updateOrganizationField: "organization/UPDATE_FIELD",
      resetOrganization: "organization/RESET_ORGANIZATION",
    }),

    hide() {
      this.displayModal = false;
    },
    show() {
      this.displayModal = true;
    },

    onClickCancel() {
      this.hide();
    },
    onClickValidate() {
      if (!this.$refs.formOffer.checkForm()) {
        return;
      }

      if (
        this.form.contactEmail &&
        this.form.contactEmail.length > 0 &&
        !Validators.isEmail(this.form.contactEmail)
      ) {
        this.$messageError("Set a valid email for the contact");
        return;
      }

      this.loading = true;
      this.updateOrganizationField({ key: "name", value: this.form.name });
      this.updateOrganizationField({ key: "status", value: this.form.status });
      this.updateOrganizationField({
        key: "marketType",
        value: this.form.marketType,
      });
      this.updateOrganizationField({
        key: "contactEmail",
        value: this.form.contactEmail,
      });
      this.updateOrganizationField({
        key: "offer",
        value: this.$refs.formOffer.getForm(),
      });
      this.updateOrganizationField({
        key: "collaborationType",
        value: this.form.collaborationType,
      });

      this.saveOrganization().then((success) => {
        if (success) {
          this.loading = false;
          // go to edit organization if success
          this.hide();
          this.$go2Route({
            name: "admin-organization-edit-organizationId",
            params: { organizationId: this.organization._id },
          });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>