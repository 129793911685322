<template>
  <div>
    <el-row class="mt-2">
      <el-col :span="8">
        <h2>Offer <small v-if="isOptionnal">(optionnal)</small></h2>
      </el-col>
      <el-col :span="8" class="el-select-offer ml-2">
        <el-select
          v-model="form.offer"
          @change="updateValidityDatesFromOffer"
          :disabled="disabled"
          clearable
        >
          <el-option v-for="offer in offers" :key="offer._id" :value="offer">
            <span>
              <MzOfferTag :offer="offer" :showCompleteTag="true" />
            </span>
          </el-option>
        </el-select>
        <MzOfferTag
          v-if="form.offer"
          :offer="form.offer"
          class="display-offer-over-select"
        />
      </el-col>
      <el-col :span="6" class="mt-0" v-if="form.offer">
        <el-button
          size="small"
          icon="el-icon-edit"
          :disabled="disabled || form.offer === null"
          @click="$refs.editOfferModal.showEdit(form.offer._id)"
        >
          Edit
        </el-button>
        <el-button
          size="small"
          icon="el-icon-document-copy"
          :disabled="disabled || form.offer === null"
          @click="$refs.editOfferModal.showDuplicate(form.offer._id)"
        >
          Duplicate
        </el-button>
      </el-col>
    </el-row>
    <el-row class="mt-0" v-if="form.offer">
      <el-col :span="10" :offset="9" class="text-style-small">
        <span v-if="form.offer.duration.durationUnit === 'illimited'">
          Expire At : Illimited
        </span>
        <span v-else-if="form.offer.duration.durationUnit === 'date'">
          Expire At : {{ form.offer.duration.durationDate | moment("L") }}
        </span>
        <span v-else>
          Expire in :
          {{
            form.offer.duration.durationTime +
            " " +
            form.offer.duration.durationUnit
          }}
        </span>
      </el-col>
      <el-col :span="10" :offset="9" class="text-style-small">
        Maximum users per magazine :
        {{
          form.offer.offerSettings.limits.numberCollaborators !== -1
            ? form.offer.offerSettings.limits.numberCollaborators
            : "unlimited"
        }}
      </el-col>
    </el-row>

    <el-row class="mt-2" v-if="form.offer">
      <el-col :span="8">
        <h2>Validity dates</h2>
      </el-col>
      <el-col :span="7" class="ml-2">
        <el-date-picker
          :disabled="disabled || form.offer === null"
          v-model="form.offerStartAt"
          type="date"
          placeholder="Start date"
          @change="updateValidityDatesFromOffer"
          :clearable="false"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="7" class="ml-2">
        <el-date-picker
          :disabled="disabled || form.offer === null"
          v-model="form.offerEndAt"
          type="date"
          placeholder="End date"
          :clearable="false"
        >
        </el-date-picker>
      </el-col>
    </el-row>

    <el-row class="mt-2" v-if="form.offer && showAvailableTemplate">
      <el-col :span="8">
        <h2>Specific available templates</h2>
      </el-col>
      <el-col :span="14" class="ml-2">
        <el-select
          class="el-col el-col-24"
          v-model="form.availableTemplates"
          :disabled="disabled || form.offer === null"
          filterable
          multiple
          placeholder="Select one or more specific template"
        >
          <el-option
            v-for="template in templateMagazines"
            :key="template._id"
            :label="template.name"
            :value="template._id"
          >
            <span style="float: left">{{ template.name }}</span>
            <span
              v-if="template.format.formatBase"
              style="
                float: left;
                color: #8492a6;
                font-size: 12px;
                margin-left: 1rem;
              "
              >{{ template.format.formatBase.name }}</span
            >
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <EditOfferModal ref="editOfferModal" @save="onSaveOffer" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import MzOfferTag from "~/components/offer/MzOfferTag";
import EditOfferModal from "~/components/offer/EditOfferModal";

export default {
  name: "AdminAddOfferForm",
  components: { MzOfferTag, EditOfferModal },

  props: ["isOptionnal", "disabled", "showAvailableTemplate"],

  data() {
    return {
      form: {
        offer: null,
        offerStartAt: null,
        offerEndAt: null,
        availableTemplates: [],
      },
    };
  },
  computed: {
    ...mapState("offer", ["offers"]),
    ...mapState("magazine", ["templateMagazines"]),

    offersById() {
      return this.offers.reduce((list, offer) => {
        list[offer._id] = offer;
        return list;
      }, {});
    },
    templatesById() {
      return this.templateMagazines
        ? this.templateMagazines.reduce((list, template) => {
            list[template._id] = template;
            return list;
          }, {})
        : {};
    },
  },

  mounted() {
    this.loadOffers();
    this.loadTemplateMagazines({
      filter: { status: "Published" },
      sort: "name",
    });
  },

  methods: {
    ...mapActions({
      loadOffers: "offer/LOAD_OFFERS",
      loadTemplateMagazines: "magazine/LOAD_TEMPLATE_MAGAZINES",
    }),

    resetForm() {
      this.form.offer = null;
      this.form.offerStartAt = null;
      this.form.offerEndAt = null;
      this.form.availableTemplates = [];
    },

    checkForm() {
      if (!this.isOptionnal && !this.form.offer) {
        this.$messageError("invalid offer");
        return false;
      }

      if (
        this.form.offer &&
        (this.form.offerStartAt === null ||
          (this.form.offerEndAt !== null &&
            this.form.offerStartAt > this.form.offerEndAt))
      ) {
        this.$messageError("invalid date");
        return false;
      }

      return true;
    },

    getForm() {
      return this.form.offer && this.form.offer._id
        ? {
            offerId: this.form.offer._id,
            offerStartAt: this.form.offerStartAt,
            offerEndAt: this.form.offerEndAt,
            availableTemplates: this.showAvailableTemplate
              ? this.form.availableTemplates
              : null,
          }
        : null;
    },

    updateValidityDatesFromOffer() {
      if (!this.form.offer) {
        this.form.offerStartAt = null;
        this.form.offerEndAt = null;
      } else {
        if (!this.form.offerStartAt) {
          this.form.offerStartAt = new Date();
        }

        if (this.form.offer.duration.durationUnit === "illimited") {
          this.form.offerEndAt = null;
        } else {
          let offerEndAt = new Date(this.form.offerStartAt);
          switch (this.form.offer.duration.durationUnit) {
            case "year":
              offerEndAt.setFullYear(
                offerEndAt.getFullYear() + this.form.offer.duration.durationTime
              );
              break;
            case "month":
              offerEndAt.setMonth(
                offerEndAt.getMonth() + this.form.offer.duration.durationTime
              );
              break;
            case "day":
              offerEndAt.setDate(
                offerEndAt.getDate() + this.form.offer.duration.durationTime
              );
              break;
            case "date":
              offerEndAt = new Date(this.form.offer.duration.durationDate);
              break;
          }
          offerEndAt.setHours(23, 59, 59);
          this.form.offerEndAt = offerEndAt;
        }
      }
    },
    onSaveOffer(offer) {
      if (!this.form.offer || this.form.offer._id != offer._id) {
        this.form.offer = offer;
      }
      this.updateValidityDatesFromOffer();
    },
  },
};
</script>

<style scoped lang="scss">
.el-select-offer {
  position: relative;
  & .el-select {
    width: 200px;
  }
}
.display-offer-over-select {
  position: absolute;
  left: 10px;
  top: 10px;
  pointer-events: none;
}
</style>